const NOTIFICATION_CATEGORY_LIST = [
  {
    id: 'all' as const,
    name: '전체보기' as const,
  },
  {
    _id: 'real_transaction' as const,
    id: 'real-trade' as const,
    name: '가장 빠른 실거래가' as const,
    icon: 'alarmCenter/icon_actual_price' as const,
  },
  {
    _id: 'richgo_now' as const,
    id: 'richgo-richgoNow' as const,
    name: '리치고 Now' as const,
    icon: 'alarmCenter/icon_richgonow' as const,
  },
  {
    _id: 'parcel_customize' as const,
    id: 'custom-parcel' as const,
    name: '가장 빠른 청약 정보' as const,
    icon: 'alarmCenter/icon_subscription' as const,
  },
  {
    _id: 'notice' as const,
    id: 'notice' as const,
    name: '부동산 동향 브리핑' as const,
    icon: 'alarmCenter/icon_updown' as const,
  },
  {
    _id: 'weekly_hoga' as const,
    id: 'weekly_hoga' as const,
    name: '가장 싼 매물 가격은?' as const,
    icon: 'alarmCenter/icon_asking_price' as const,
  },
  {
    _id: 'update_history' as const,
    id: 'update_history' as const,
    name: '업데이트 알림' as const,
    icon: 'alarmCenter/icon_update' as const,
  },
  {
    _id: 'checklist' as const,
    id: 'checklist' as const,
    name: '부동산 투자체크' as const,
    icon: 'alarmCenter/icon_checklist' as const,
  },
  {
    _id: 'registry' as const,
    id: 'registry' as const,
    name: '등기 변동 알림' as const,
    icon: 'alarmCenter/문서_경고' as const,
  },
  {
    _id: 'auction' as const,
    id: 'auction' as const,
    name: '경매 정보' as const,
    icon: 'alarmCenter/auction' as const,
  },
  {
    _id: 'widget_hoga' as const,
    id: 'widget_hoga' as const,
    name: '매물 최저가 위젯' as const,
    icon: 'pictogram/스마트폰' as const,
  },
];

export default NOTIFICATION_CATEGORY_LIST;
