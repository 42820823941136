import React, { useEffect } from 'react';

import useSystem from 'coreSrc/base/systemManager/useSystem';

import { ButtonLarge, Flex, Header, TextView } from '@dataknows/richgo-orbit';
import * as Sentry from '@sentry/nextjs';
import styled from 'styled-components';

type ErrorPageProps = {
  errorData: {
    error?: Error;
    componentStack?: string;
    eventId?: string;
    resetError?: () => void;
  };
};

function ErrorPage({ errorData }: ErrorPageProps) {
  const sys = useSystem();
  const isMobile = sys.detector.isMobile;

  useEffect(() => {
    if (!errorData.error) {
      return;
    }
    Sentry.captureException(errorData.error);
  }, [errorData.error]);

  return (
    <Container
      padding={'20px 16px'}
      flexDir="column"
    >
      <Flex
        flex={1}
        flexDir="column"
        justify="center"
        align="center"
      >
        <BoxInCatSvg />
        <Header
          headline="알 수 없는 에러가 발생했어요 🥲"
          size={isMobile ? 'medium' : 'large'}
        />
        <TextView
          text="잠시 후 다시 시도해보세요"
          bullet={false}
          size="large"
          color="secondary"
        />
      </Flex>
      <ButtonLarge
        label="지도로 이동"
        onClick={() => {
          window.location.href = '/';
        }}
        stretch
      />
    </Container>
  );
}

const BoxInCatSvg = () => (
  <img
    src={'/icons/svgFiles/page/errorPage.svg'}
    style={{ width: 160, height: 160 }}
  />
);
const Container = styled(Flex)`
  height: calc(100vh - 56px);
`;
export default ErrorPage;
